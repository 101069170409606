.ourMaterialsPage {
  padding-top: 50px; /* Add space for the fixed header */

  .firstBlock {
    background-image: url("../../../../../public/images/OurMaterials/our-materials-background.png"); /* Default background */
    background-size: cover;
    background-position: center;
    padding: 100px 20px;
    text-align: center;
    color: #fff; /* White text to contrast the background */

    /* Use @2x image for larger screens */
    @media only screen and (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2),
      only screen and (min-resolution: 192dpi) {
      background-image: url("../../../../../public/images/OurMaterials/our-materials-background@2x.svg");
    }

    .content {
      max-width: 800px; /* Set a max width for the content */
      margin: 0 auto; /* Center the content */
      text-align: center;

      h1 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        margin: auto auto 27px auto;
        max-width: 486px;
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        max-width: 355px;
        margin: auto;
      }
    }
  }

  .secondBlock {
    text-align: center;
    padding: 60px 20px;

    h2 {
      font-size: 40px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      margin: auto auto 20px auto;
      color: #050d9a;
      max-width: 360px;
    }

    .description {
      font-size: 18px;
      font-family: "Seravek", sans-serif;
      margin-bottom: 40px;
      color: #050d9a;
      font-weight: 600;
    }

    .columns {
      display: flex;
      justify-content: space-around;
      gap: 20px;

      .column {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        text-align: left; /* Keep left alignment */
        max-width: 256px;

        .columnImage {
          width: 100%; /* Fix image alignment issue by using width instead of min-width */
          height: auto;
          border-radius: 4px;
          margin-bottom: 20px; /* Add space between image and title */
        }

        h3 {
          font-size: 20px;
          font-family: "Gilroy-ExtraBold", sans-serif;
          margin-bottom: 10px;
          color: #36384a;
          margin-left: 20px;
        }

        .columnDescription {
          font-size: 14px;
          font-family: "Seravek", sans-serif;
          color: #36384a;
          margin-left: 20px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .firstBlock {
      .content {
        h1 {
          max-width: 345px;
        }
      }
    }

    .secondBlock {
      h2 {
        font-size: 20px;
      }
      .columns {
        flex-direction: column;

        .column {
          text-align: center;
          margin: auto;

          .columnImage {
            max-width: 140px;
          }

          h3 {
            margin-left: 0;
          }

          .columnDescription {
            margin-left: 0;
          }
        }
      }
    }
  }
}
