.manageSnippetsContainer {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;

  .title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 2rem;
  }

  .addButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 2rem;
  }

  .addButton:hover {
    background-color: #218838;
  }

  .errorMessage {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
  }

  .searchFilters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
  }

  .searchInput {
    padding: 0.5rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 250px;
  }

  .snippetsList {
    list-style: none;
    padding: 0;

    .snippetItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .snippetName {
        flex: 1; // Adjust width as needed
        font-weight: bold;
      }

      .snippetStatus {
        flex: 0.5; // Adjust width as needed
        font-size: 14px;
        color: #666;
        text-align: center;
      }

      .editButton {
        background-color: #ffc107;
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .editButton:hover {
        background-color: #e0a800;
      }

      .deleteButton {
        background-color: #dc3545;
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
      }

      .deleteButton:hover {
        background-color: #c82333;
      }
    }
  }
}
