@import "../../../../assets/styles/fonts.scss";

.mobileFooter {
  position: relative;
  background-color: #083ebb;
  color: hsl(0, 0%, 100%);
  padding: 40px 50px;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
  }

  .logoContainer {
    margin-bottom: 20px;

    .logo {
      width: 102px; /* Adjust the size of the logo as needed */
    }
  }

  .whatsapp {
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 100;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;

    .column {
      margin-bottom: 20px;

      h4 {
        margin-bottom: 10px;
        font-size: 18px;
        font-family: "Gilroy-ExtraBold", sans-serif;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 8px;

          a {
            color: #fff;
            text-decoration: none;
            font-family: "Seravek", sans-serif;
            font-size: 14px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .footerMisc {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        margin-right: 90px;
      }

      .backToTop {
        text-decoration: none;

        .backToTopIcon {
          width: 20px;
          height: 20px;
          background-color: #083ebb;
          border-radius: 50%;
          border: 2px solid #bbbbbb;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          padding: 10px;

          .backToTopIcon {
            width: 18px;
            height: 22px;
            color: #fff;
          }

          &:hover {
            background-color: #083ebb;
          }
        }
      }
    }
  }
}
