/* Center the login form on the page */
.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;

  .loginForm {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;

    .loginTitle {
      text-align: center;
      font-size: 24px;
      margin-bottom: 1rem;
      color: #333;
    }

    .loginInput {
      width: 100%;
      padding: 0.75rem;
      margin-bottom: 1rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
    }

    .loginButton {
      width: 100%;
      padding: 0.75rem;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
    }

    .loginButton:hover {
      background-color: #0056b3;
    }

    .loginError {
      color: red;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}
