.addProductContainer {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;

  .title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 2rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;

    label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    input,
    textarea,
    select {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }

    button {
      background-color: #007bff;
      color: white;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }

    .imageContainer {
      display: flex;
      flex-direction: column;
      .removeButton {
        background-color: #dc3545;
        width: 26%;
        margin-top: 20px;
      }
    }

    .removeButton {
      background-color: #dc3545;
      margin-top: 20px;
    }

    .secondaryImageItem {
      display: flex;
      align-items: center;
      gap: 1rem;

      input {
        flex: 1;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 4px;
        border: 1px solid #ddd;
      }

      button {
        background-color: #dc3545;
        color: white;
        padding: 0.5rem;
        border-radius: 4px;
        width: 100% !important;

        &:hover {
          background-color: #c82333;
        }
      }
    }

    .imagePreview {
      margin-top: 1rem;
      width: 150px;
      height: 150px;
      object-fit: cover;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }
}
