.categoriesListContainer {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;

  .title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 2rem;
  }

  .searchFilters {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .searchInput {
    padding: 0.5rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 300px; // Limit the width of search input
  }

  .backButton {
    background-color: #6c757d; // Gray background
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
    margin-right: 50px;
  }

  .backButton:hover {
    background-color: #5a6268; // Darker gray on hover
  }

  .addButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 2rem;
  }

  .addButton:hover {
    background-color: #0056b3;
  }

  .errorMessage {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
  }

  .noCategories {
    text-align: center;
    color: #555;
  }

  .categoryList {
    list-style: none;
    padding: 0;

    .categoryItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .editButton {
      background-color: #28a745;
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      margin-right: 0.5rem;
      cursor: pointer;
    }

    .editButton:hover {
      background-color: #218838;
    }

    .deleteButton {
      background-color: #dc3545;
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;
    }

    .deleteButton:hover {
      background-color: #c82333;
    }
  }

  .confirmDeleteOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .confirmDeletePopup {
      background-color: white;
      padding: 2rem;
      border-radius: 8px;
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .confirmButton {
      background-color: #dc3545;
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 0.5rem;
    }

    .cancelButton {
      background-color: #6c757d;
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .editModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .editModalPopup {
      background-color: white;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      width: 300px;
      text-align: center;
    }

    label {
      display: block;
      margin-bottom: 1rem;
      font-size: 16px;
    }

    input {
      width: 80%;
      padding: 0.75rem;
      margin-top: 0.5rem;
      border-radius: 4px;
      border: 1px solid #ddd;
      font-size: 16px;
    }

    // Add dropdown styling here
    select {
      width: 80%;
      padding: 0.75rem; // Same padding as input
      margin-top: 0.5rem;
      border-radius: 4px; // Same rounded corners
      border: 1px solid #ddd; // Same border style
      font-size: 16px; // Same font size as input
      background-color: white;
      appearance: none; // Hide native dropdown arrow (optional, for custom styling)
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .saveButton {
      background-color: #28a745;
      color: white;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 1rem;
    }

    .cancelButton {
      background-color: #dc3545;
      color: white;
      padding: 0.75rem 1.5rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
