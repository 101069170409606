.contactItem {
  display: flex;
  align-items: flex-start;

  .icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .text {
    h3 {
      font-size: 18px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      color: #050d9a;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      font-family: "Seravek", sans-serif;
      line-height: 24px;
      color: #ababb6;
    }
  }

  /* Mobile adjustments */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon {
      margin-right: 0;
      margin-bottom: 10px;
    }

    .text {
      min-width: 70%;
    }
  }
}
