.hospitalityPage {
  .firstBlock {
    background-image: url("../../../../../../public/images/ForYourBusiness/Hospitality/hospitality-header.jpg"); /* Background image */
    background-size: cover; /* Ensure the background covers the block */
    background-repeat: no-repeat; /* Prevent repeating the image */
    background-position: center; /* Center the image */
    width: 100%; /* Full width */
    height: 473px; /* Set the height to the exact dimensions */
    text-align: left;
    color: #fff;
    position: relative;
    z-index: 1;
    margin-top: 100px;

    .content {
      max-width: 800px; /* Set a max width for the content */
      margin-left: 10%;
      padding-top: 100px;
      position: relative;
      z-index: 2; /* Ensure content is above the overlay */

      h1 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        margin-bottom: 20px;
        color: #050d9a;
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        color: #050d9a;
        max-width: 320px;
      }
    }
  }

  .secondBlock {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 60px 10%;
    background-color: #e8edf2;
    position: relative; /* To position the background image */
    min-height: 619px;

    /* Background PNG on the left */
    &::before {
      content: "";
      background-image: url("../../../../../../public/images/ForYourBusiness/Hospitality/hospitality-cross-pattern.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%; /* Adjust width to ensure it covers the left part */
      height: 100%;
      z-index: 1; /* Make sure it's behind the content */
    }

    .leftImage {
      position: relative; /* For content positioning */
      z-index: 2; /* Ensure it's above the background */

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .rightContent {
      flex: 1;
      padding-left: 40px; /* Space between image and text */
      z-index: 2; /* Ensure text is above the background */
      max-width: 370px;

      h2 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        color: #050d9a;
        margin-bottom: 20px;
      }

      .mobileImage {
        display: none; /* Hide the image on desktop */
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 24px;
        color: #050d9a;
      }
    }
  }

  .thirdBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 80px 20px;
    background-image: url("../../../../../../public/images/ForYourBusiness/Hospitality/hospitality-mattress-background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    position: relative;
    min-height: 650px; /* Ensure the block takes up enough height */

    h2 {
      font-size: 40px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      margin-bottom: 20px;
      color: #050d9a; /* White text for contrast */
    }

    p {
      font-size: 14px;
      font-family: "Seravek", sans-serif;
      line-height: 17px;
      max-width: 460px;
      margin-bottom: 30px;
      color: #050c9a;
    }

    .collectionsButton {
      background-color: #073dba;
      color: #fff;
      padding: 10px 30px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      height: 41px;
      min-width: 176px;
    }
  }

  .fourthBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Left-align the content */
    padding: 80px 150px;
    background-image: url("../../../../../../public/images/ForYourBusiness/Hospitality/hospitality-biolatex-background.jpg"); /* Add your background image */
    background-size: cover; /* Make the background cover the entire block */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat;
    text-align: left; /* Ensure text is left-aligned */
    min-height: 500px; /* Ensure the block has enough height */

    h2 {
      font-size: 40px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      margin-bottom: 20px;
      color: #050d9a;
      max-width: 360px;
    }

    p {
      font-size: 14px;
      font-family: "Seravek", sans-serif;
      line-height: 17px;
      max-width: 320px; /* Set a max-width for readability */
      margin-bottom: 30px;
      color: #050d9a;
    }

    .biolatexButton {
      background-color: #073dba;
      height: 41px;
      min-width: 170px;
      color: #fff;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  /* Mobile adjustments */
  @media (max-width: 768px) {
    .firstBlock {
      margin-top: 75px; /* Adjust margin for smaller screens */

      .content {
        margin-left: 0%; /* Adjust margin for smaller screens */
        padding-top: 50px; /* Reduce padding for mobile */
        text-align: center; /* Center content on mobile */

        p {
          //   font-size: 12px; /* Adjust font size for mobile */
          max-width: 90%; /* Increase the width of the description */
          margin: auto; /* Center the paragraph */
        }
      }
    }

    .secondBlock {
      flex-direction: column;
      padding: 30px 20px;
      text-align: center; /* Center-align text on mobile */
      position: relative;

      /* Rotate the background image and move it below the content */
      &::before {
        background-image: url("../../../../../../public/images/ForYourBusiness/Hospitality/hospitality-cross-pattern.png");
        background-size: contain;
        background-position: center bottom;
        background-repeat: no-repeat;
        transform: rotate(90deg); /* Rotate the background */
        width: 85%;
        height: 65%;
        top: auto;
        bottom: -150px; /* Adjust this if necessary to ensure visibility */
        z-index: 0; /* Push the background behind all content */
      }

      .rightContent {
        padding-left: 0; /* Remove padding for mobile */
        z-index: 2; /* Ensure text is above the background */

        h2 {
          font-size: 24px;
        }

        .mobileImage {
          display: block; /* Show the image on mobile */
          //   margin: 20px auto; /* Center the image */
          margin-top: 70px;
          height: auto;

          img {
            max-width: 100%;
          }
        }

        p {
          font-size: 14px;
        }
      }

      .leftImage {
        display: none;
      }
    }

    .thirdBlock {
      padding: 50px 20px;
      background-size: cover; /* Make the background cover the entire block */
      background-position: calc(40% - 20px) 50%; /* Adjust the background position */
      background-repeat: no-repeat; /* Ensure the image doesn't repeat */
      min-height: 570px;
      height: auto; /* Allow the block to adjust to its content */

      h2 {
        font-size: 24px; /* Adjust title size for mobile */
      }

      p {
        font-size: 14px;
        max-width: 90%;
      }

      .collectionsButton {
        padding: 10px 20px;
      }
    }

    .fourthBlock {
      padding: 50px 20px;
      background-size: cover; /* Ensure the background still covers the block */
      text-align: center; /* Maintain left-aligned text */

      h2 {
        font-size: 40px; /* Adjust title size for mobile */
      }

      p {
        max-width: 300px;
        margin: 0 auto 50px auto; /* Center the paragraph */
      }

      .biolatexButton {
        margin: 0 auto 0 auto; /* Center the button */
      }
    }
  }
}
