.aboutNAMPage {
  padding-top: 50px; /* Add space for the fixed header */
  .firstBlock {
    background-image: url("../../../../../public/images/AboutNAM/about-nam-background.png"); /* Background image */
    background-size: cover; /* Cover the entire block */
    background-position: center; /* Center the background image */
    padding: 100px 20px; /* Add padding to create space around the content */
    text-align: center; /* Center the text */
    color: #fff; /* White text for contrast */
    position: relative;
    z-index: 1;

    .content {
      max-width: 800px;
      margin: 0 auto; /* Center the content block */
      position: relative;
      z-index: 2; /* Ensure text is above the overlay */

      h1 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        margin: auto auto 20px auto;
        color: #fff;
        max-width: 500px;
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        color: #fff;
        max-width: 360px;
        margin: auto;
      }
    }
  }

  .secondBlock {
    display: flex;
    padding: 80px 20px;

    /* This ensures all the content stays vertically aligned at the top */
    flex-grow: 1;
    justify-content: center;

    .leftSide,
    .rightSide {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center; /* Center the content horizontally */
      padding: 20px;
      text-align: center;
      max-width: 320px;
      margin: 0 50px;

      h2 {
        font-size: 20px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        color: #36384a;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        color: #36384a;
        max-width: 340px;
        margin: auto;
      }
    }

    .leftSide {
      .imageContainer {
        height: 211px;
        width: 230px;
        margin: auto;
        margin-bottom: 20px;
        img {
          max-width: 85%;
          height: auto;
        }
      }
    }

    .rightSide {
      .imageContainer {
        height: 211px;
        width: 230px;
        img {
          height: 100%;
        }
      }

      p {
        font-family: "Seravek", sans-serif;
        padding-top: 30px;
      }
    }

    .divider {
      width: 1px;
      background-color: #d3d3d3;
      height: 50%; /* Full height for the divider */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .thirdBlock {
    background-color: #fff; /* White background */
    border-top: 1px solid #d3d3d3; /* Grey top border */
    padding: 40px 20px;

    .row {
      display: flex;
      margin: auto auto 30px auto;
      max-width: 828px;
    }

    .column {
      flex: 1;
      text-align: center;
      padding: 20px;
      max-width: 300px;
      margin: auto;

      img {
        max-width: 100%;
        height: auto;
        // margin-bottom: 15px;
      }

      h3 {
        font-size: 20px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        color: #050d9a;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        color: #36384a;
        line-height: 1.6;
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 768px) {
    .secondBlock {
      flex-direction: column;
      padding: 40px 20px;

      .divider {
        width: 50%; /* Make the divider take up the full width */
        height: 1px; /* Change height to 1px for a horizontal line */
        position: relative; /* Set relative positioning for better alignment */
        margin: 20px 0; /* Add vertical spacing */
        transform: none; /* Remove previous transformation */
        align-self: center; /* Center the divider */
        left: 0;
      }
    }

    .thirdBlock {
      .row {
        flex-direction: column;
      }
    }
  }
}
