.medicalPage {
  .firstBlock {
    background-image: url("../../../../../../public/images/ForYourBusiness/Medical/medical-header.jpg"); /* Background image */
    background-size: cover; /* Ensure the background covers the block */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background from repeating */
    width: 100%; /* Full width */
    min-height: 500px; /* Ensure the block has enough height */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: flex-start; /* Align content to the left */
    padding: 80px 10%; /* Add padding to the content */
    text-align: left; /* Left-align the text */
    position: relative; /* Allow positioning of elements */
    z-index: 1;
    margin-top: 100px;

    h1 {
      font-size: 40px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      margin-bottom: 20px;
      color: #050d9a; /* White text for contrast */
      max-width: 625px;
    }

    p {
      font-size: 14px;
      font-family: "Seravek", sans-serif;
      line-height: 17px;
      max-width: 300px; /* Limit the width of the description for readability */
      color: #050d9a;
    }
  }

  .secondBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 10%;
    background-color: #fff; /* White background */
    min-height: 500px; /* Ensure enough height for the block */

    .leftImage {
      max-width: 600px; /* Set image width */

      img {
        width: 100%; /* Ensure image covers the full width of the container */
        height: auto;
      }
    }

    .rightContent {
      max-width: 45%; /* Adjust width for right content */
      padding-left: 40px; /* Add space between image and text */

      h2 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        color: #050d9a;
        margin-bottom: 20px;
        max-width: 400px; /* Limit title width for readability */
      }

      .mobileImage {
        display: none; /* Hide the image on desktop */
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        color: #050d9a;
        max-width: 300px;
      }
    }
  }

  .thirdBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 10%;
    background-color: #d6d1ce; /* Background color */
    min-height: 500px;

    .leftContent {
      max-width: 50%; /* Left content takes half width */
      h2 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        color: #050d9a;
        margin-bottom: 20px;
        max-width: 410px;
      }

      .mobileImage {
        display: none;
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        color: #050d9a;
        margin-bottom: 30px;
        max-width: 300px;
      }
      .discoverButton {
        padding: 10px 20px;
        background-color: #050d9a;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        height: 41px;
        min-width: 260px;
      }
    }

    .rightImage {
      max-width: 600px;
      img {
        width: 100%; /* Ensure the image covers the full width of the container */
        height: auto;
      }
    }
  }

  /* Mobile adjustments */
  @media (max-width: 768px) {
    .firstBlock {
      background-size: cover; /* Make the background cover the entire block */
      background-position: calc(55% - 20px) 50%; /* Adjust the background position */
      background-repeat: no-repeat; /* Ensure the image doesn't repeat */
      padding: 50px 0; /* Reduce padding for mobile */
      min-height: 400px; /* Reduce height for mobile */
      text-align: center; /* Center text for mobile */
      margin-top: 75px;

      /* Light overlay for mobile */
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5); /* Light white overlay */
        z-index: 1; /* Ensure it's behind the text */
      }

      h1 {
        font-size: 50px; /* Reduce title size for mobile */
        z-index: 2; /* Ensure title is above the overlay */
      }

      p {
        font-size: 14px; /* Reduce description size for mobile */
        max-width: 60%; /* Full width for mobile */
        margin: 0 auto; /* Center the description */
        z-index: 2; /* Ensure title is above the overlay */
      }
    }

    .secondBlock {
      flex-direction: column; /* Stack the image and content vertically */
      padding: 30px 20px;
      justify-content: space-around;
      text-align: center; /* Center the text on mobile */

      .leftImage {
        display: none; /* Hide the image on mobile */
      }

      .rightContent {
        padding-left: 0; /* Remove padding on mobile */
        max-width: 100%;

        h2 {
          font-size: 24px; /* Reduce title size for mobile */
        }

        .mobileImage {
          display: block; /* Show the image on mobile */
          max-width: 90%; /* Set image width */
          margin: 20px auto; /* Center the image */
          img {
            width: 100%; /* Ensure image covers the full width of the container */
            height: auto;
          }
        }

        p {
          font-size: 14px; /* Adjust description size for mobile */
          margin: 20px auto; /* Center the description */
          max-width: 88%; /* Increase the width of the description */
        }
      }
    }

    .thirdBlock {
      flex-direction: column; /* Stack content vertically on mobile */
      padding: 30px 20px;
      justify-content: space-between;
      text-align: center; /* Center text for mobile */

      .leftContent {
        max-width: 100%; /* Full width for mobile */
        margin-right: 0;
        h2 {
          font-size: 24px;
        }
        .mobileImage {
          display: block; /* Show the image on mobile */
          max-width: 90%; /* Set image width */
          margin: 20px auto; /* Center the image */
          img {
            width: 100%; /* Ensure image covers the full width of the container */
            height: auto;
          }
        }
        p {
          font-size: 14px;
          margin: 20px auto; /* Center the description */
        }
        .discoverButton {
          font-size: 14px;
          margin: 20px auto; /* Center the button */
        }
      }

      .rightImage {
        display: none; /* Hide the image on mobile */
      }
    }
  }
}
