.productPage {
  margin-top: 150px;

  .breadCrumbContainer {
    margin-left: 15px;
  }

  .mainContent {
    display: flex;

    .productContent {
      display: flex;
      flex-direction: column;

      .productName {
        text-align: center;
        color: #050d9a;
        font-family: "Gilroy-ExtraBold", sans-serif;
        font-size: 40px;
      }
    }

    .rightContent {
      width: 60%;
      height: 70%;
      margin: auto;

      .tabs {
        display: flex;
        gap: 15px;
        margin-bottom: 20px;
        justify-content: center;

        .tabButton {
          padding: 10px 20px;
          // border: 1px solid #ccc;
          border: none;
          background-color: #f8f8f8;
          cursor: pointer;
          font-weight: bold;
          transition: background-color 0.3s;
          font-family: "Seravek", sans-serif;
          font-size: 20px;

          &.active {
            background-color: #050d9a;
            color: #fff;
            border-bottom: none;
          }

          &:hover {
            border: 1px solid #050d9a;
          }
        }
      }
      .tabContent {
        font-size: 16px;
        line-height: 1.6;
        text-align: center;
        width: 60%;
        margin: auto;

        .infoRow {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          strong {
            font-weight: bold;
            color: #333;
          }

          span {
            color: #555;
          }
        }
        @media only screen and (max-width: 768px) {
          width: 90%;
        }
      }
    }
  }

  .separator {
    margin: 20px 0;
    border: 1px solid #ccc;
  }

  .relatedProductsContainer {
    margin-top: 20px;
    margin-bottom: 25px;

    h2 {
      color: #050d9a;
      font-family: "Gilroy-ExtraBold", sans-serif;
      font-size: 25px;
      margin-bottom: 20px;
      text-align: center;
    }

    .relatedProducts {
      display: flex;
      gap: 15px;
      overflow-x: auto; // Enable horizontal scroll for overflow
      padding-bottom: 10px;
      margin: 20px;

      &::-webkit-scrollbar {
        display: none; // Hide scrollbar in WebKit browsers
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        div {
          width: 100% !important;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .mainContent {
      flex-direction: column;

      .productContent {
        .productName {
          font-size: 24px;
        }
      }

      .rightContent {
        width: 90%;
        .tabs {
          .tabButton {
            font-size: 14px;
          }
        }

        .tabContent {
          width: 90%;
          font-size: 12px;
        }
      }
    }
  }
}
