.dashboardContainer {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;

  .dashboardTitle {
    text-align: center;
    font-size: 32px;
    margin-bottom: 2rem;
  }

  .dashboardActions {
    display: flex;
    justify-content: center;
    gap: 1.5rem;

    .dashboardLink {
      padding: 1rem 2rem;
      background-color: #28a745;
      color: white;
      text-decoration: none;
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }

    .dashboardLink:hover {
      background-color: #218838;
    }
  }
}
