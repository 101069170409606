.mobileMenu {
  display: none;

  @media (max-width: 768px) {
    display: block;
    background-color: #083ebb;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; /* Ensure full width of the viewport */
    box-sizing: border-box; /* Include padding in width calculations */
    z-index: 1000;
  }

  .topBar {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the logo */
    position: relative;
    padding: 5px;
  }

  .menuButton {
    cursor: pointer;
    padding: 5px;
    position: absolute;
    left: 10px; /* Position on the left of the topBar */

    .burgerIcon,
    .closeIcon {
      width: 30px;
      height: 3px;
      background-color: #fff;
      position: relative;
      transition: all 0.3s ease;
    }

    .burgerIcon:before,
    .burgerIcon:after,
    .closeIcon:before,
    .closeIcon:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #fff;
      transition: all 0.3s ease;
    }

    .burgerIcon:before {
      top: -8px;
    }

    .burgerIcon:after {
      top: 8px;
    }

    .closeIcon:before {
      transform: rotate(45deg);
    }

    .closeIcon:after {
      transform: rotate(-45deg);
    }
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    .logo {
      width: 90px; /* Adjust size as needed */
    }
  }

  .menuContent {
    background-color: #083ebb;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 100%; /* Start below the topBar */
    left: 0;
    width: 100%;
    height: calc(
      100vh - 50px
    ); /* Ensure the menu covers the remaining viewport height */
    overflow-y: auto;
    z-index: 1000;
    box-sizing: border-box; /* Include padding in width calculations */

    ul {
      list-style: none;
      padding-top: 50px;
      padding-left: 0;
      margin: 0;

      li {
        padding: 10px 0;

        a {
          color: #fff;
          font-family: "Gilroy-ExtraBold", sans-serif;

          text-decoration: none;
          font-size: 24px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .submenu {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 10px !important;
    color: #fff;
    font-family: "Seravek", sans-serif;
    font-size: 18px;
    justify-content: center; /* Center the text */
    position: relative;

    span {
      text-decoration: none;
      font-family: "Gilroy-ExtraBold", sans-serif;
      font-size: 24px;
    }

    .arrowDown,
    .arrowUp {
      position: absolute;
      right: 10px;
      border: solid #ccc;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transition: transform 0.3s ease;
    }

    .arrowDown {
      transform: rotate(45deg);
    }

    .arrowUp {
      transform: rotate(-135deg);
    }
  }

  .submenuList {
    padding-left: 20px;
    padding-top: 10px !important;

    li {
      padding: 8px 0;

      a {
        font-size: 16px !important;
        font-family: "Seravek", sans-serif;
        font-weight: normal !important;
      }
    }
  }

  .contactButton {
    background-color: #050d9a;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    font-family: "Seravek", sans-serif;
    font-size: 18px;
    border-radius: 4px;
    margin-top: 80px;
    display: inline-block;
    text-decoration: none;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}
