.productsPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden; /* Prevent horizontal scroll */

  .firstBlock {
    background-image: url("../../../../../public/images/ProductsPage/products-page-header.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    margin-top: 100px;

    h1 {
      font-size: 40px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      color: #083ebb;
    }
  }

  .secondBlock {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;
  }

  .blockContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    position: relative;
    gap: 15px;
    margin-bottom: 50px;

    .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      height: 196px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .image {
      width: 55px;
      height: 55px;
      object-fit: cover;
      margin-bottom: 20px;
    }

    .title {
      font-size: 18px;
      font-family: "Seravek", sans-serif;
      margin: 10px 0;
      color: #050c9a;
    }

    .text {
      font-family: "Seravek", sans-serif;
      font-size: 14px;
      color: #050c9a;
    }

    .divider {
      width: 1px;
      height: 200px;
      background-color: #050c9a;

      @media (max-width: 768px) {
        width: 80%;
        height: 1px;
        margin: 20px 0;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  /* Slider Block */
}

@media (max-width: 768px) {
  .firstBlock {
    padding: 0 !important;
    margin-top: 20px !important;
  }

  .secondBlock {
    padding: 40px 5px;
    max-width: 300px !important;
  }
}
