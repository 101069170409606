.breadcrumbMolecule {
  display: flex;
  align-items: center;
  font-size: 18px;

  .breadcrumbItem {
    color: #050c9a;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .breadcrumbActive {
    color: #050c9a;
    font-weight: bold;
  }

  .breadcrumbSeparator {
    margin: 0 5px;
    color: #050c9a;
  }
}
