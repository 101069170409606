.firstBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  /* Background image on the left (conditionally applied) */
  &.withBackground {
    justify-content: space-around; /* Change to space-between when background is present */
    align-items: flex-start;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 132px;
      height: 110%;
      background-image: url("../../../../../public/images/ContactUs/contact-pattern.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
      z-index: 0;
    }
  }

  /* Contact Form */
  .contactForm {
    flex: 1;
    max-width: 35%;
    padding: 20px;

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .formGroup {
        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          font-family: "Seravek", sans-serif;
          line-height: 16px;
          margin-bottom: 5px;
          color: #ababb6;
        }

        input,
        textarea {
          padding: 10px;
          font-family: "Seravek", sans-serif;
          border: 1px solid rgb(191, 191, 191);
          border-radius: 4px;
          font-size: 14px;
          background-color: #f4f4f6;
        }

        textarea {
          min-height: 120px;
          resize: vertical;
        }
      }

      .sendButton {
        background-color: #050d9a;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        font-family: "Seravek", sans-serif;
        font-weight: bold;
        align-self: flex-start;
        min-width: 100%;

        &:hover {
          background-color: #072a8c;
        }
      }
    }
  }

  /* Bottom image */
  .bottomImage {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    img {
      max-width: 100px;
    }
  }

  /* Mobile adjustments */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;

    &.withBackground::before {
      display: none;
    }

    .contactForm {
      text-align: left;
      min-width: 80%;
      padding: 0 20px;
      margin-top: 30px;
    }

    /* If background is NOT present */
    &:not(.withBackground) .contactForm {
      margin-bottom: 30px;
    }

    .bottomImage {
      margin-top: 40px;

      img {
        max-width: 80px;
      }
    }
  }
}
