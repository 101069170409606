.contactUsPage {
  padding: 50px 0;
  background-color: #fff; /* Background color white */
  margin-top: 100px; /* Space from the top */

  .mapBlock {
    width: 90%;
    margin: 150px auto 75px auto;
  }
}
