.productListingPage {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 150px;
  @media (max-width: 768px) {
    margin-top: 100px;
  }

  .headerRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 5%;
    align-items: center;

    /* Responsive styles for mobile */
    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }

    .resultsCount {
      font-size: 16px;
      color: #333;
      margin-right: 10%;
    }
  }

  .filterButton {
    display: none; /* Hide filter button by default */

    /* Only show on mobile */
    @media (max-width: 768px) {
      display: block;
      margin: 10px 0;
      padding: 10px 20px;
      font-size: 16px;
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }
  }

  .clearAllButton {
    background-color: #050c9a;
    border: 1px solid #ccc;
    color: #fff;
    padding: 10px 20px;
    margin-top: 15px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 20px;
  }

  .clearAllButton:hover {
    background-color: #007bff;
  }

  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;

    .closeButton {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 24px;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .productListingPageContainer {
    display: flex;
    gap: 20px;

    /* Sidebar takes 35% of the screen width, grid takes 65% */
    .leftSidebar {
      width: 40%;
      margin-left: 5%;
      @media (max-width: 768px) {
        display: none; /* Hide sidebar on mobile */
      }
    }

    .rightSideBar {
      max-width: 71%;
      @media (max-width: 480px) {
        max-width: 100%;
      }
    }

    .grid {
      width: 65%; /* Product grid occupies max 65% of screen width */
      @media (max-width: 768px) {
        width: 100%; /* Full width on mobile */
        grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
      }
      @media (max-width: 480px) {
        grid-template-columns: 1fr; /* 1 column on very small screens */
      }
    }
  }

  .leftSidebar {
    /* Hide left sidebar on mobile */
    @media (max-width: 768px) {
      display: none;
    }
  }
}
