.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContainer {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;

  h2 {
    margin-bottom: 1.5rem;
  }

  input,
  textarea {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
  }

  textarea {
    height: 100px;
  }

  .buttonGroup {
    display: flex;
    justify-content: space-between;

    .saveButton {
      background-color: #28a745;
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      cursor: pointer;
    }

    .saveButton:hover {
      background-color: #218838;
    }

    .cancelButton {
      background-color: #dc3545;
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      cursor: pointer;
    }

    .cancelButton:hover {
      background-color: #c82333;
    }
  }
}
