/* src/components/organisms/Header/modules/Header.module.scss */

@import "../../../../assets/styles/fonts.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #083ebb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Ensure padding is part of the width */
  z-index: 1000;

  /* Hide on mobile devices */
  @media (max-width: 768px) {
    display: none;
  }

  .logoNavContainer {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .logo {
    cursor: pointer;
    padding: 0 20px;
    margin-right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .navTabs {
    display: flex;
    gap: 33px;
    height: 100%;
    align-items: center;
  }

  .navTab {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    a {
      color: #fff;
      text-decoration: none;
      font-family: "Seravek-Medium", sans-serif;
      font-size: 13px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      position: relative;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }

    &:hover .dropdown {
      display: block;
    }
  }

  .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: -5%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 0;
    width: 120%;
    margin-top: 0px;

    li {
      list-style: none;

      a {
        display: block;
        padding: 15px 0;
        color: #083ebb;
        text-decoration: none;
        font-family: "Seravek", sans-serif;
        font-size: 15px;
        text-align: center;

        &:hover {
          background-color: #f0f0f0;
          text-decoration: none;
        }
      }

      &:nth-child(2) a {
        border-top: 1px solid #083ebb;
        border-bottom: 1px solid #083ebb;
      }
    }
  }

  .searchContainer {
    position: relative; /* Ensure the search dropdown and "No results" are positioned relative to this container */
    display: inline-block; /* Keep the container in place without affecting other elements */
    width: 200px; /* Adjust width as needed */
  }

  .searchField {
    padding-left: 35px;
    background-color: #083ebb;
    border: none;
    color: transparent;
    outline: none;
    transition: color 0.3s ease, border-color 0.3s ease;
    min-height: 40px;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: transparent;
      transition: color 0.3s ease;
    }

    &:hover,
    &:focus {
      border: 2px solid #fff;
      color: #fff;
      border-radius: 10px;
    }

    &:hover::placeholder,
    &:focus::placeholder {
      color: #9d9d9d;
    }
  }

  .searchDropdown {
    position: absolute;
    top: 100%; /* Aligns the dropdown below the search bar */
    left: 0;
    background-color: #fff; /* White background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 100%; /* Match the width of the search bar */
    max-height: 300px; /* Optional: Limit height */
    overflow-y: auto; /* Allow vertical scrolling if there are many results */
    border-radius: 4px; /* Add some rounded corners */
    overflow-x: hidden; /* Prevent horizontal scrollbars */
    padding: 10px 0; /* Add some padding around the items */
  }

  .searchResultItem {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0; /* Highlight on hover */
    }

    img {
      width: 40px; /* Set image width */
      height: 40px; /* Set image height */
      object-fit: cover; /* Keep the aspect ratio */
      margin-right: 10px; /* Space between image and text */
      border-radius: 50%; /* Optional: Make images round */
    }

    span {
      font-family: "Seravek", sans-serif;
      font-size: 14px;
      color: #083ebb; /* Match text color with your theme */
    }
  }

  .searchIcon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    .searchImage {
      max-width: 21px;
      max-height: 20px;
      color: #fff;
    }
  }

  .contactButton {
    margin-left: 20px;
    height: 41px;
    width: 120px;
    border: none;
    background-color: #050d9a;
    color: #fff;
    font-size: 14px;
    font-family: "Seravek", sans-serif;
    font-weight: 700;
  }
}
