.contactInfo {
  flex: 1;
  max-width: 33%;
  padding-left: 132px;

  &.businessPage {
    padding-left: 0;
    max-width: 35%;
  }

  h1 {
    font-size: 40px;
    font-family: "Gilroy-ExtraBold", sans-serif;
    color: #083ebb;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    font-family: "Seravek", sans-serif;
    line-height: 17px;
    color: #050c9a;
    margin-bottom: 30px;
    max-width: 448px;
  }

  .contactItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  /* Mobile adjustments */
  @media (max-width: 768px) {
    max-width: 100%;
    padding-left: 0;

    &.businessPage {
      max-width: 100%;
    }

    h1 {
      font-size: 20px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      font-family: "Seravek", sans-serif;
      max-width: 90%;
      margin: 0 auto 30px auto;
    }

    .contactItems {
      grid-template-columns: 1fr;
    }
  }
}
