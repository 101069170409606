.backButton {
  background-color: #6c757d; // Gray background
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-right: 50px;
  &:hover {
    background-color: #5a6268; // Darker gray on hover
  }
}
