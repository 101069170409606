.sliderBlock {
  padding: 40px 20px;
  position: relative;
  max-width: 1040px;
  margin: 0 auto;

  .sliderTitle {
    font-size: 40px;
    font-family: "Gilroy-ExtraBold", sans-serif;
    color: #083ebb;
    margin-bottom: 20px;
  }

  .swiper {
    width: 100%;

    .swiperSlide {
      width: 350px !important; /* Fixed width for desktop */

      .sliderItem {
        position: relative;
        width: 328px;
        height: 300px;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: flex-end;

        .sliderImage {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .sliderItemTitle {
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: rgba(255, 255, 255, 0.8);
          padding: 10px 20px;
          font-size: 1.2rem;
          font-weight: bold;
          color: #333;
          border-radius: 5px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sliderBlock {
    max-width: 100%;
    margin-left: 0;

    .sliderTitle {
      font-size: 20px !important;
    }

    .swiper {
      margin-left: 0;

      .swiperSlide {
        width: 300px !important; /* Reduced width for mobile */
      }
    }
  }
}
