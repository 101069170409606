.gridContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.productBox {
  position: relative;
  width: 288px;
  height: 270px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column; /* Ensure label stacks under the image */

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex-grow: 1; /* Let the image take up the remaining space */
}

.label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  font-family: "Gilroy-ExtraBold", sans-serif;
  font-size: 20px;
  color: #083ebb;
  padding: 0 10%;
  text-align: left;
  p {
    width: 70%;
    margin: 15px;
  }
}
