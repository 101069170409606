.card {
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  transition: transform 0.3s ease;
  cursor: pointer;
  aspect-ratio: 6 / 7; /* Set a wide aspect ratio (4:3 width to height) */
  width: 100%; /* Ensure card takes full width of the grid cell */
  height: auto; /* Height will adjust based on the aspect ratio */

  &:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure image covers the card area without distortion */
    display: block;
  }

  .cardName {
    position: absolute;
    bottom: -80px; /* Initially hidden outside of view */
    left: 0;
    width: 100%;
    background-color: #083ebb; /* Blue background */
    color: white;
    text-align: center;
    padding: 10px 0;
    font-size: 18px;
    line-height: 50px;
    transition: bottom 0.3s ease; /* Smooth slide-up transition */
  }

  &:hover .cardName {
    bottom: 0; /* Slide up to reveal on hover */
  }
}
