.logoutButton {
  position: absolute;
  left: 2%;
  top: 2%;
  background-color: indianred;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
}
