h3 {
  margin-bottom: 12px;
  font-size: 18px;
  color: #36384a;
}

.categoryContainer {
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
  .categoryName {
    padding: 10px 0;
    font-size: 18px;
    color: #334862;
    cursor: pointer;
    transition: background-color 0.2s ease-in;
  }

  .subCategoryList {
    padding-left: 10px;
    border-left: 1px solid #eaeaea;
    .subCategoryItem {
      color: #334862;
      font-size: 16px;
    }
  }
  .selected {
    font-weight: bold;
    color: #050c9a !important;
  }
}

/* Bold the selected category or subcategory */
