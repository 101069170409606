@font-face {
  font-family: "Gilroy-ExtraBold"; /* General family name */
  src: url("../../assets/fonts/Gilroy-ExtraBold.ttf") format("truetype"); /* Adjust path if needed */
  font-weight: 800; /* Extra Bold usually corresponds to 800 */
  font-style: normal;
}

@font-face {
  font-family: "Seravek"; /* General family name */
  src: url("../../assets/fonts/Seravek_Basic.otf") format("opentype"); /* Adjust path if needed */
  font-weight: 400; /* Regular usually corresponds to 400 */
  font-style: normal;
}

@font-face {
  font-family: "Seravek-Medium"; /* General family name */
  src: url("../../assets/fonts/Seravek_Basic_Medium.otf") format("opentype"); /* Adjust path if needed */
  font-style: normal;
}
