.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 3-column layout */
  gap: 20px;
  padding: 20px;
  max-width: 85%;

  .loading {
    font-size: 16px;
    color: #666;
    text-align: center;
    padding: 20px;
  }

  .errorMessage {
    font-size: 16px;
    color: red;
    text-align: center;
    padding: 20px;
  }

  /* Adjust column count based on screen size */
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
    width: 100%; /* Full width on mobile */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* 1 column on very small screens */
  }
}
