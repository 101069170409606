// src/components/molecules/ImageGallery/modules/ImageGallery.module.scss

.imageGallery {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  .mainImageContainer {
    width: 60%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer; // Indicate that the image is clickable
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
  }

  .mainImage {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .thumbnailContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .thumbnail {
    width: 100px;
    height: auto;
    cursor: pointer;
    object-fit: cover;
    border: 2px solid transparent;
    transition: border 0.2s;

    &:hover {
      border-color: #ccc;
    }

    &.active {
      border-color: #050d9a;
    }

    @media only screen and (max-width: 768px) {
      width: 75px;
    }
  }

  // Expanded overlay styles
  .expandedOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); // Semi-transparent dark background
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .expandedImage {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 15px;
    .thumbnailContainer {
      flex-direction: row;
    }
  }
}
