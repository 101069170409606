.specialCarePage {
  .firstBlock {
    background-image: url("../../../../../../public/images/ForYourBusiness/SpecialCare/header.jpg"); /* Background image */
    background-size: cover; /* Ensure background covers entire block */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent image from repeating */
    width: 100%; /* Full width */
    min-height: 500px; /* Minimum height */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: flex-start; /* Align content to the left */
    padding: 80px 10%; /* Padding for content */
    margin-top: 100px; /* Add space at the top */
    text-align: left; /* Left align the text */
    position: relative; /* Allow positioning */
    z-index: 1;

    .content {
      max-width: 800px; /* Set a max width for the content */
      z-index: 2; /* Ensure content is above background */

      h1 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        margin-bottom: 20px;
        color: #050d9a; /* Text color */
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        color: #050d9a;
        max-width: 440px; /* Limit width of the description */
      }
    }
  }

  .secondBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8edf2; /* Background color */
    padding: 60px 0; /* Adjust padding for spacing */

    .leftImage {
      //   flex: 1;
      min-width: 640px; /* Set a minimum width for the image */
      img {
        max-width: 100%;
        height: auto; /* Ensure the image scales properly */
      }
    }

    .rightContent {
      flex: 1;
      padding-left: 40px; /* Space between image and text */
      max-width: 400px; /* Limit width of the content */

      h2 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        color: #050d9a;
        margin-bottom: 20px;
        max-width: 355px; /* Limit width of the title */
      }

      .mobileImage {
        display: none; /* Hide the image on desktop */
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        color: #050d9a;
        max-width: 305px;
      }
    }
  }

  .thirdBlock {
    background-image: url("../../../../../../public/images/ForYourBusiness/SpecialCare/room-view.jpg"); /* Background image */
    background-size: cover; /* Ensure the background covers the block */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background from repeating */
    padding: 80px 10%; /* Adjust padding for spacing */
    color: #fff; /* White text for contrast */
    text-align: center; /* Align text to the left */
    min-height: 750px; /* Minimum height for the block */

    h2 {
      font-size: 40px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      margin-bottom: 20px;
      color: #050d9a; /* Title color */
      max-width: 666px; /* Limit width of the title */
      margin: 20px auto; /* Center the title */
    }

    p {
      font-size: 14px;
      font-family: "Seravek", sans-serif;
      line-height: 17px;
      color: #050d9a; /* Text color */
      max-width: 570px; /* Limit width of the paragraph for readability */
      margin: 20px auto 50px auto; /* Center the text */
    }

    .collectionsButton {
      background-color: #050d9a;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;

      &:hover {
        background-color: #072a8c; /* Darker blue on hover */
      }
    }
  }

  .fourthBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 10%;
    background-color: #f5f5f5; /* Light background color */
    color: #050d9a; /* Text color */

    .leftContent {
      flex: 1;
      max-width: 500px;

      h2 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        margin-bottom: 20px;
        max-width: 454px; /* Limit width of the title */
      }

      .mobileImage {
        display: none; /* Hide the image on desktop */
      }

      p {
        font-size: 14px;
        font-family: "Seravek", sans-serif;
        line-height: 17px;
        margin-bottom: 30px;
        max-width: 310px; /* Limit width of the paragraph */
      }

      .biolatexButton {
        background-color: #050d9a;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;

        &:hover {
          background-color: #072a8c; /* Darker blue on hover */
        }
      }
    }

    .rightImage {
      flex: 1;
      text-align: right;
      max-width: 540px;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px; /* Optional: Rounded corners */
      }
    }
  }

  /* Mobile adjustments */
  @media (max-width: 768px) {
    .firstBlock {
      background-size: cover; /* Ensure the background covers the entire block */
      background-position: center; /* Center the image within the block */
      background-repeat: no-repeat; /* Ensure the image doesn't repeat */
      padding: 50px 0; /* Reduce padding for mobile */
      min-height: 400px; /* Ensure the block's height is consistent */
      text-align: center; /* Center text for mobile */
      margin-top: 75px; /* Adjust margin for mobile */

      .content {
        min-width: 100%; /* Full width for content */
        margin: 0; /* Center content on mobile */

        h1 {
          font-size: 30px; /* Reduce font size for mobile */
          max-width: 335px; /* Full width for title */
          margin: auto;
        }

        p {
          font-size: 14px;
          max-width: 335px; /* Full width for title */
          margin: auto;
        }
      }
    }

    .secondBlock {
      flex-direction: column;
      text-align: center;
      padding: 50px 20px;

      .leftImage {
        display: none; /* Hide the image on mobile */
      }

      .rightContent {
        padding-left: 0; /* Remove left padding on mobile */

        h2 {
          font-size: 20px; /* Adjust title size for mobile */
          max-width: 225px; /* Full width for title */
          margin: 20px auto; /* Center the title */
        }

        .mobileImage {
          display: block; /* Show the image on mobile */
          margin: 20px auto; /* Center the image */
          max-width: 90%; /* Full width for the image */

          img {
            width: 100%;
          }
        }

        p {
          font-size: 14px;
          margin: 20px auto; /* Center the description */
        }
      }
    }

    .thirdBlock {
      padding: 40px 20px;
      text-align: center; /* Center text on mobile */
      min-height: 350px; /* Minimum height for the block */

      h2 {
        font-size: 20px; /* Adjust title size for mobile */
      }

      p {
        font-size: 14px;
        max-width: 95%;
        margin: 20px auto; /* Center the text */
      }

      .collectionsButton {
        padding: 10px 20px;
      }
    }

    .fourthBlock {
      flex-direction: column;
      text-align: center;
      padding: 40px 20px;

      .leftContent {
        margin-bottom: 20px;

        h2 {
          font-size: 20px; /* Adjust title size for mobile */
          max-width: 70%;
          margin: 20px auto; /* Center the title */
        }

        .mobileImage {
          display: block; /* Show the image on mobile */

          img {
            width: 100%;
          }
        }

        p {
          font-size: 14px; /* Adjust paragraph size for mobile */
          margin: 20px auto; /* Center the description */
        }

        .biolatexButton {
          padding: 10px 20px;
        }
      }

      .rightImage {
        display: none; /* Hide the image on mobile */
      }
    }
  }
}
