.manageCategoriesContainer {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;

  .title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 2rem;
  }

  .backButton {
    background-color: #6c757d; // Gray background
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .backButton:hover {
    background-color: #5a6268; // Darker gray on hover
  }

  .form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .label {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    .input,
    .select {
      padding: 0.75rem;
      margin-bottom: 1rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
    }

    .button {
      padding: 0.75rem;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
    }

    .button:hover {
      background-color: #218838;
    }

    .successMessage {
      color: green;
      text-align: center;
    }

    .errorMessage {
      color: red;
      text-align: center;
    }
  }
}
