.featureBlock {
  padding: 60px 140px;
  border-top: 1px solid #d6d1cf;

  .textContent {
    text-align: left;
    margin-bottom: 40px;

    h2 {
      font-size: 40px;
      font-weight: bold;
      color: #050d9a;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      color: #050d9a;
      font-weight: 600;
      margin-bottom: 20px;
      max-width: 600px;
    }
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    max-width: 800px;

    .feature {
      display: flex;
      align-items: flex-start;
      border-radius: 8px;
      max-width: 330px;

      img {
        width: 66px;
        height: 66px;
        object-fit: contain; /* Ensure the image scales properly */
      }

      .featureText {
        max-width: 280px;
        h3 {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 5px;
          color: #36384a;
        }

        p {
          font-size: 14px;
          line-height: 17px;
          color: #36384a;
          min-width: 270px;
          opacity: 0.6;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 40px;

    .textContent {
      text-align: center;

      h2 {
        font-size: 20px;
      }
    }

    .features {
      gap: 20px;

      .feature {
        .featureText {
          p {
            min-width: 100px;
          }
        }
      }
    }
  }
}
