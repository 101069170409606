.homePage {
  padding-top: 50px; /* Add space for the fixed header */

  .firstBlock {
    display: flex;
    align-items: center;
    background-color: #083ebb;
    padding: 90px 150px 100px 150px;

    .leftContent {
      flex: 1;
      max-width: 47.5%;
      padding-right: 20px;

      h1 {
        font-size: 60px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        margin-bottom: 20px;
        color: #fff;
      }

      .description {
        font-size: 16px;
        font-family: "Seravek", sans-serif;
        font-weight: 200;
        margin-bottom: 49px;
        color: #fff;
      }

      .mobileImageFirst {
        display: none;
      }

      .iconColumns {
        display: flex;

        .column {
          display: flex;
          flex-direction: column;

          .item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            font-weight: 500;

            .icon {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }

            span {
              font-size: 12px;
              font-family: "Seravek-Medium", sans-serif;
              color: #fff;
            }
          }
        }

        .column + .column {
          margin-left: 40px;
        }
      }
    }

    .rightContent {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .bigImage {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .secondBlock {
    position: relative; /* To position the background image */
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 60px 50px; /* Adjust padding as needed */
    background-color: #050c9a; /* Optional: background color */

    .leftContent {
      flex: 1;
      display: flex;
      //   justify-content: space-around;

      .mattressImage {
        max-width: 80%;
        height: auto;
      }
    }

    .rightContent {
      flex: 1;
      padding-left: 20px; /* Space between image and text */
      max-width: 340px;

      .ressortIcon {
        max-width: 80px; /* Adjust size as needed */
        margin-bottom: 0;
      }

      h2 {
        font-size: 40px;
        font-family: "Gilroy-ExtraBold", sans-serif;
        margin-bottom: 20px;
        margin-top: 0;
        color: #fff;
      }

      .description {
        font-size: 14px;
        line-height: 17px;
        font-family: "Seravek", sans-serif;
        color: #fff;
        margin-bottom: 15px;
        max-width: 330px;
      }

      .mobileMattressImage {
        display: none;
      }

      .materialsButton {
        background-color: #050c9a;
        font-family: "Seravek", sans-serif;
        color: #fff;
        padding: 10px 20px;
        border: 2px solid #fff;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          background-color: #072a8c;
        }
      }
    }

    .backgroundImage {
      position: absolute;
      bottom: -130px;
      right: 0;
      max-width: 400px; /* Adjust size as needed */
    }
  }

  .thirdBlock {
    position: relative; /* For background positioning */
    background-color: #e7ecf2;
    padding: 20px 150px 60px 150px;
    text-align: center; /* Center the title and image at the top */

    h2 {
      font-size: 40px;
      font-family: "Gilroy-ExtraBold", sans-serif;
      margin-bottom: 20px;
      color: #083ebb;
    }

    .ressortLong {
      margin-bottom: 40px;
      max-width: 100%;
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      text-align: left; /* Align text on the left content */

      .leftContent {
        flex: 1;
        max-width: 45%;

        .description {
          font-size: 14px;
          font-family: "Seravek", sans-serif;
          line-height: 17px;
          color: #050c9a;
          margin-bottom: 30px;
          max-width: 330px;
        }

        .productsButton {
          background-color: #050c9a;
          color: #fff;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-family: "Seravek", sans-serif;
          font-size: 16px;
          font-weight: bold;

          &:hover {
            background-color: #072a8c;
          }
        }
      }

      .rightContent {
        flex: 1;
        display: flex;
        justify-content: center;
        max-width: 500px;

        .mattressImage {
          width: 100%;
          height: auto;
        }
      }
    }

    .backgroundImage {
      position: absolute;
      bottom: 0;
      left: -200px;
      max-width: 300px;
    }
  }

  .fourthBlock {
    background-color: #fff;
    padding: 60px 50px;
    text-align: center;

    .ressortLong {
      margin-bottom: 40px;
      max-width: 100%;
    }

    .cardContainer {
      display: flex;
      flex-direction: row; /* Stack rows on top of each other */
      flex-wrap: wrap; /* Allow wrapping of rows */
      justify-content: center;
      gap: 10px; /* Space between the rows */

      .cardRow {
        display: flex;
        justify-content: space-between;
        gap: 10px; /* Space between the cards */

        .card {
          background-color: #fff;
          padding: 20px;
          transition: all 0.3s ease;
          flex: 1; /* Make each card take up equal space */
          text-align: center;
          border: 1px solid transparent; /* No border initially */
          width: 210px;

          &:hover {
            border-color: #ccc; /* Add border on hover */
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
          }

          .cardImage {
            width: 100%;
            height: 262px;
            margin-bottom: 20px;
          }

          h3 {
            font-family: "Gilroy-ExtraBold", sans-serif;
            font-size: 20px;
            margin-bottom: 15px;
            color: #083ebb;
          }

          .cardDescription {
            font-size: 14px;
            margin: 0 auto 20px auto;
            color: #050c9a;
            max-width: 175px;
          }

          .cardButton {
            background-color: #050d9a;
            color: #fff;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;

            &:hover {
              background-color: #072a8c;
            }
          }
        }
      }
    }
  }

  .fifthBlock {
    background-color: #083ebb;
    color: #fff;
    text-align: center;
    padding: 60px 20px;
    border-bottom: 1px solid #050c9a;

    h2 {
      font-family: "Gilroy-ExtraBold", sans-serif;
      font-size: 40px;
      margin: 0 auto 20px auto;
      max-width: 456px;
    }

    .description {
      font-size: 14px;
      font-family: "Seravek", sans-serif;
      line-height: 17px;
      margin: 0 auto 30px auto;
      max-width: 420px;
    }

    .materialsButton {
      background-color: #050d9a;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      font-family: "Seravek", sans-serif;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .imageContainer {
      margin-top: 40px;
      display: flex; /* Make the container a flexbox */
      justify-content: center; /* Center content horizontally */
      align-items: center; /* Center content vertically */

      .mattressImage {
        margin: auto;
      }

      .desktopImage {
        display: block;
      }

      .mobileImage {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    .firstBlock {
      flex-direction: column; /* Stack elements vertically */
      padding: 20px;

      .leftContent {
        max-width: 100%; /* Full width for the content */
        padding-right: 0; /* Remove right padding */

        h1 {
          font-size: 36px; /* Adjust title size for mobile */
          text-align: center; /* Center the title */
        }

        .description {
          font-size: 14px; /* Adjust description size for mobile */
          text-align: center; /* Center the description */
          margin-bottom: 20px;
        }

        .mobileImageFirst {
          display: block; /* Show the image on mobile */
          text-align: center; /* Center the image */
          img {
            width: 312px;
            height: 234px;
          }
        }

        .iconColumns {
          flex-direction: row; /* Change to a horizontal layout */
          flex-wrap: wrap; /* Allow wrapping of items */
          margin-top: 20px;

          .column {
            flex-direction: row; /* Align items horizontally in mobile view */
            justify-content: center;
            width: 100%; /* Make each column full width */
            margin-bottom: 10px;

            .item {
              flex: 1 1 50%; /* 50% width for each item */
              flex-direction: column;
              margin-bottom: 15px;
              justify-content: center; /* Center items horizontally */
              text-align: center; /* Center text */

              img {
                margin-bottom: 5px;
              }
            }
          }

          .column + .column {
            margin-left: 0; /* Remove the margin between columns */
          }
        }
      }

      .rightContent {
        display: none; /* Hide the image on mobile */
      }
    }

    .secondBlock {
      flex-direction: column; /* Stack elements vertically */
      padding: 20px; /* Adjust padding as needed */
      text-align: center; /* Center content */

      .leftContent {
        display: none;
      }

      .rightContent {
        padding-left: 0; /* Remove left padding */
        max-width: 100%; /* Full width for the content */

        .ressortIcon {
          max-width: 20px; /* Adjust size as needed */
          margin-bottom: 20px;
        }

        h2 {
          font-size: 28px; /* Adjust title size for mobile */
        }

        .description {
          font-size: 14px; /* Adjust description size for mobile */
          margin-bottom: 20px;
          width: 80%;
          margin: auto;
          max-width: 100%; /* Full width for the description */
        }

        .mobileMattressImage {
          display: block;
          max-width: 345px;
          margin: 20px auto;
        }

        .materialsButton {
          margin: 0 auto; /* Center the button */
        }
      }

      .backgroundImage {
        display: none;
      }
    }

    .thirdBlock {
      padding: 40px;

      h2 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #083ebb;
      }

      .ressortLong {
        margin-bottom: 10px;
        max-width: 22px;
      }

      .content {
        flex-direction: column-reverse;
        text-align: center;

        .leftContent {
          max-width: 90%;

          .description {
            font-size: 14px;
            line-height: 17px;
            max-width: 330px;
          }
        }
      }

      .backgroundImage {
        display: none;
      }
    }

    .fourthBlock {
      padding: 60px 5%;

      .cardContainer {
        flex-direction: column; /* Stack cards vertically */
        gap: 20px;

        .cardRow {
          max-width: 100%;
          .card {
            max-width: 40%;

            .cardImage {
              width: 90%;
              height: auto;
            }

            h3 {
              font-size: 18px;
            }

            .cardDescription {
              margin: 0 0 20px 0;
              font-size: 12px;
            }
          }
        }
      }
    }

    .fifthBlock {
      h2 {
        font-size: 20px;
        line-height: 20px;
      }

      .description {
        max-width: 315px;
      }

      .imageContainer {
        justify-content: flex-start; /* Align content to the left */
        .desktopImage {
          display: none; /* Hide desktop image */
        }

        .mobileImage {
          display: block; /* Show mobile image */
          width: 150%; /* Adjust width as needed */
          margin: 0 0 0 -150px; /* Remove auto margin to align left */
        }
      }
    }
  }
}
