/* src/components/organisms/Footer/modules/Footer.module.scss */

.footer {
  position: relative;
  background-color: #083ebb;
  color: #fff;
  padding: 95px 170px 60px 156px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Hide on mobile devices */
  @media (max-width: 768px) {
    display: none;
  }

  .footerContent {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .leftSection {
      display: flex;
      gap: 128px;

      .column {
        display: flex;
        flex-direction: column;

        .columnTitle {
          font-size: 16px;
          font-family: "Gilroy-ExtraBold", sans-serif;
          margin-bottom: 10px;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            margin-bottom: 8px;

            a {
              color: #fff;
              text-decoration: none;
              font-family: "Seravek", sans-serif;
              font-size: 14px;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .rightSection {
      display: flex;
      align-items: center;
      flex-direction: column;

      .logo {
        max-width: 150px;
      }
      .whatsapp {
        text-align: center;
        margin-top: 40px;
        position: fixed;
        right: 5%;
        bottom: 10%;
      }
    }
  }

  .footerBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;

    p {
      margin: 0;
      font-family: "Seravek", sans-serif;
      font-size: 14px;
    }

    .backToTop {
      text-decoration: none;

      .backToTopIcon {
        width: 30px;
        height: 30px;
        background-color: #083ebb;
        border-radius: 50%;
        border: 2px solid #bbbbbb;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        padding: 10px;
        margin-right: 20px;

        .backToTopIcon {
          width: 18px;
          height: 22px;
          color: #fff;
        }

        &:hover {
          background-color: #083ebb;
        }
      }
    }
  }
}
