.manageProductsContainer {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;

  .title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .backButton,
  .addButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
  }

  .backButton:hover,
  .addButton:hover {
    background-color: #0056b3;
  }

  .searchFilters {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 1fr; /* Match productItem layout */
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;

    .searchInput {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
    }

    .searchByName {
      grid-column: 1 / 2; /* Align with Product Name column */
    }

    .searchByCategory {
      grid-column: 2 / 3; /* Align with Category column */
    }
  }

  .productsList {
    list-style-type: none;
    padding: 0;
    margin-top: 1.5rem;

    .productItem {
      display: grid;
      grid-template-columns: 3fr 2fr 1fr 1fr; /* Adjust columns for alignment */
      gap: 10px;
      align-items: center;
      background-color: #fff;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .productName {
        font-size: 1.2rem;
      }

      .productCategory {
        font-size: 1.1rem;
        color: #6c757d;
      }

      .editButton,
      .deleteButton {
        background-color: #28a745;
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
      }

      .deleteButton {
        background-color: #dc3545;
      }

      .editButton:hover {
        background-color: #218838;
      }

      .deleteButton:hover {
        background-color: #c82333;
      }
    }
  }

  /* Overlay for delete confirmation */
  .confirmDeleteOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
    display: flex;
    justify-content: center;
    align-items: center; /* Center the popup */
    z-index: 1000;
  }

  /* Delete confirmation popup */
  .confirmDeletePopup {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;

    p {
      font-size: 1.1rem;
      margin-bottom: 1.5rem;
    }

    .confirmButton {
      background-color: #dc3545;
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 1rem;
    }

    .cancelButton {
      background-color: #6c757d;
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      cursor: pointer;
    }

    .confirmButton:hover {
      background-color: #c82333;
    }

    .cancelButton:hover {
      background-color: #5a6268;
    }
  }
}
